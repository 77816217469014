/*
 *  ======================================= 
 *  HOME PAGE | _home_page
 *  =======================================  
 */
.section-slider {
  overflow:hidden;
  margin-bottom: 10px;
  .container {
    position: relative;
  }
  &-holder{
    float:left;
    width:100%;
  }
  &-elem{
    float:left;
    width:100%;
    position:relative;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
    &-apla {
      position: absolute;
      top: 30px;
      left: 30px;
      padding: 25px;
      @include background-opacity(#000000, 0.8);
      color: map-get($colors,'white');
      width: calc((100% - 60px) / 2);
      h3 {
        color: #fff;
        margin: 0 0 15px;
      }
      p {
        margin: 0;
      }
    }
  }
  .owl-dots {
    width: 100%;
    text-align: center;
    .owl-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 18px 5px 8px;
      background: #CDCDCD;
      &.active {
        background: #000;
      }
    }
  }
  .owl-playpause {
    position: absolute;
    bottom: 5px;
    right: 15px;
    font-size: 24px;
    z-index: 3;
    a {
      color: map-get($colors,'greytext');
      cursor: pointer;
      &:hover {
        text-decoration: none;
        color: map-get($colors,'black');
      }
    }
  }
  .owl-nav {
    .owl-prev, .owl-next {
      position: absolute;
      left: -14px;
      top: 50%;
      @include translate(0,calc(-50% - 14px));
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      text-align: center;
      float: left;
      background-color: map-get($colors,'black');
      color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
    .owl-prev {
      i {
        margin-right: 2px;
      }
    }
    .owl-next {
      left: auto;
      right: -14px;
      i {
        margin-left: 2px;
      }
    }
  }
  [class*="disabled"]{
    display:none;
  }

}
.section-homepage-boxy {
  margin-bottom: 40px;
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    &:before, &:after {
      display: none;
    }
  }
  a {
    width: 100%;
    height: 100%;
    display: block;
    min-height: 250px;
    &.with_title {
      &:before {
        content: '';
        display: block;
        margin: 10px;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        position: absolute;
        bottom: 0;
        left: 0;
        @include transition(ease, 0.5s);
        @include background-opacity(map_get($colors,'black'), 0);
      }
      &:hover {
        &:before {
          @include background-opacity(map_get($colors,'black'), 0.4);
        }
        button {
          opacity: 1;
        }
      }
    }
  }
  &-elem {
    &-ins {
      background-size: cover;
      background-repeat: no-repeat;
      background-color: map_get($colors,'black');
      position: relative;
      height: 100%;
    }
    &-title {
      h3 {
        font-size: 24px;
        margin: 0;
        padding: 15px 20px;
        display: block;
        width: 100%;
        text-align: left;
        color: #fff;
        @include transition(ease,0.3s);
        @include background-opacity(map_get($colors,'black'), 0.6);
        position: absolute;
        left: 0;
        bottom: 0;
      }
      button {
        opacity: 0;
        @include transition(ease,1s);
      }
    }
    button {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
    &-txt {
      color: #fff;
      font-size: 16px;
      line-height: 190%;
      padding: 30px;
      font-family: 'Raleway', sans-serif;
      padding-bottom: 60px;
      button {
        left: 30px;
      }
    }
    a:hover {
      .section-homepage-boxy-elem-title {
        h3 {
          background: none;
          position: absolute;
          bottom: 190px;
        }
      }
      .section-homepage-boxy-elem-txt {
        color: #fff;
        text-decoration: none;
      }
      button {
        &:before {
          animation: scale 1s infinite;
        }
      }
    }
  }
}