/*
 *  ======================================= 
 *  PROJEKT TWORZONY ZA POMOCA
 *  PREPROCESORA SCSS
 *	JESLI CHCESZ EDYTOWAC COKOLWIEK EDYTUJ
 *  PLIKI SASS  SCSS
 *  =======================================  
 */


@import 'modules/_elements';
@import 'partials/_variables';
@import 'fonts/_font-awesome';
@import 'fonts/_lemonicon';
@import 'fonts/_argon-technika';

@import 'partials/_global';
@import 'partials/_home_page';

  
   