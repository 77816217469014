/*
 *  ======================================= 
 *  VARIABLES | _variables
 *  =======================================  
 */
$font_directory : '../fonts/';
$font_opensans  : 'Open Sans', sans-serif;
$container_padding : 0 30px;
$header_elem_padding : (
        'size1':0 40px,
        'size2':0 21px,
        'size3':0 18px
);

$colors : (
        'black':#000000,
        'greytext':#7d7d7d,
        'white':#ffffff
);