/*
 *  ======================================= 
 *  GLOBAL | _global
 *  =======================================  
 */
body {
  color: map_get($colors,'black');
  font-size: 17px;
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;
  line-height: 180%;
}
a {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

h1, h2, h3, h4, h5 {
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: map_get($colors,'black');
}
h1 {
  font-size: 28px;
  margin: 25px 0;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
  margin: 30px 0 20px;
}
h4 {
  font-size: 18px;
  margin: 20px 0 25px;
}
h5 {
  font-size: 17px;
}
p {
  margin: 0 0 20px;
}
hr {
  border-color: map_get($colors,'greytext');
  background: none;
}

.btn-arrow {
  background-color: #000;
  color: #fff;
  border: 0;
  padding: 0px 15px;
  height: 36px;
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
  line-height: 36px;
  span {
    margin-left: 10px;
  }
  &:before {
    content: "";
    display: inline-block;
    width: 76px;
    height: 100%;
    background: url('../images/arrow-white.png') no-repeat center center;
    float: left;
  }
  &:focus {
    outline: none;
  }
  &-white {
    background-color: #fff;
    color: #000;
    &:before {
      background-image: url('../images/arrow-black.png');
    }
  }
  &:hover {
    &:before {
      animation: scale 1s infinite;
    }
  }
}

@keyframes scale {
  0%   { transform: scaleX(1.0) translateZ(0);}
  50% { transform: scaleX(1.15) translateZ(0);}
  100% { transform: scaleX(1.0) translateZ(0);}
}

ul {
  &.lista-check {
    list-style-type: none;
    padding: 0;
    li {
      padding: 5px 0px 20px 80px;
      background-image: url('/frontend/images/icon-check.png');
      background-position: 25px 10px;
      background-repeat: no-repeat;
      position: relative;
    }
  }
  &.lista-myslniki {
    list-style-type: none;
    padding: 0;
    li {
      padding: 5px 0px 5px 20px;
      position: relative;
      &:before {
        content: '-';
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
}
.layout-container {
  padding: 0;
}
.p-email, .p-phone {
  padding-left: 25px;
  position: relative;
  margin-bottom: 0;
  &:before {
    position: absolute;
    left: 0;
    top: 5px;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.p-email {
  &:before {
    content: "\e900";
  }
}
.p-phone {
  &:before {
    content: "\e905";
  }
}

header {
  nav {
    width: 100%;
    position: relative;
    font-family: 'Raleway', sans-serif;
    >ul {
      width: 100%;
      display: block;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      white-space: nowrap;
      list-style-type: none;
      padding: 0;
      margin: 0;
      &.hidden-for-scripts {
        display: block;
        position: absolute;
        top: 0;
        opacity: 0;
        pointer-events: none;
        width: 100%;
        z-index: -1;
        a {
          padding: 0;
        }
      }
      >li{
        display: inline-block;
        border-bottom: 1px solid map-get($colors,'black');
        padding: 0;
        line-height: 55px;
        height: 55px;
        width: 190px;
        position: relative;
        &:before {
          display: none !important;
        }
        &:last-child{
          margin-right: 0;
        }
        a{
          color: #000;
          float:none;
          font-weight:400;
          font-size: 18px;
          padding: 0;
          display: block;
          text-align: center;
          i {
            margin-left: 10px;
          }
        }
        &:hover > a, &.active > a, &:focus, a:focus {
          background:map-get($colors,'black');
          color: #fff;
          text-decoration: none;
        }

        > ul{
          display: none;
          padding-left: 0;
          position: absolute;
          left: 0;
          top: 100%;
          z-index: 2;
          li{
            transition:height 200ms ease-in;
            display: block;
            padding-left: 0 !important;
            height: auto;
            line-height: 120%;
            text-align: left;
            &:before {
              display: none !important;
            }
            a{
              background-color: map-get($colors,'lightred2');
              color: map-get($colors,'almostwhite');
              font-weight: 400;
              display: block;
              padding: 6px 20px;
              padding-top: 11px;
              padding-bottom: 11px;
              text-align: left;
              &:hover {
                background: map-get($colors,'lightred');
                color: #fff;
              }
            }
          }
        }
        &:hover > ul {
          display: block;
        }
      }
    }
  }
  .logo-top {
    a {
      max-width: 190px;
      display: inline-block;
      padding: 8px 0 0;
    }
    svg {
      max-width:100%;
    }
  }
  .menu-section {
    border-top: 1px solid #CCCCCC;
    margin-bottom: 35px;
  }
}

.header-right {
  padding-top: 35px;
  &-elem {
    display: inline-block;
    float: right;
    margin-left: 20px;
    span {
      font-size: 35px;
      line-height: 35px;
      &.icon-pinterest {
        .path1 {
          &:before {
            color: rgb(203, 32, 39);
          }
        }
      }
      &.icon-facebook {
        .path1 {
          &:before {
            color: rgb(59, 89, 152);
          }
        }
      }
    }
    a {
      &:hover, &:focus {
        text-decoration: none;

      }
    }
  }
}
.change-language {
  a {
    background: map-get($colors,'black');
    color: #fff;
    @include border_radius(50%);
    text-align: center;
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    &:hover {
      background: map_get($colors, 'white');
      border: 1px solid map_get($colors, 'black');
      color: map_get($colors, 'black');
    }
  }
}

footer {
  background: #EBEBEB;
  padding: 25px 0;
  float: left;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  a {
    color: #0b0b0b;
  }
  & > .container {
    & > div {
      padding: 0;
    }
  }
}

.section {
  &-subpage {
    color: map_get($colors,'greytext');
    strong {
      color: map_get($colors,'black');
      font-weight: 400;
    }
  }
}


.mfp-gallery {
  .mfp-image-holder {
    .mfp-figure {
      background: #fff;
      padding: 5px;
    }
  }
}
.mfp-zoom-out-cur {
  .mfp-image-holder {
    .mfp-close {
      right: 6px;
    }
  }
}
img.mfp-img {
  padding-top: 0;
}
.mfp-counter {
  display: none;
}
.mfp-bottom-bar {
  background: #fff;
  color: black;
  padding: 15px 15px 20px;
  font-size: 15px;
}
.mfp-title {
  color: map_get($colors,'black');
}
button.mfp-arrow {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  &:before {
    color: #fff;
    border: none;
    font-size: 20px;
    display: inline-block;
    position: relative;
    margin: 0;
  }
  &:after {
    display: none;
  }
}
button.mfp-arrow-right {
  &:before {
    content: '\f105';
  }
}
button.mfp-arrow-left {
  &:before {
    content: '\f104';
  }
}


#map {
  width: 100%;
  height: 300px;
  margin-top: 25px;
}





.alert {
  ul {
    li {
      padding: 0;
      &:before {
        content: '';
        background: none;
      }
    }
  }
}


.hamburger {
  padding: 15px 15px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  position: absolute;
  top: 24px;
  right: 0px;
  display: none;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
    opacity: 1;
  }
  &-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }
  &-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    &::before, &::after {
      content: "";
      display: block;
    }
    &::before {
      top: -10px;
    }
    &::after {
      bottom: -10px;
    }
  }
  &-inner, &-inner::before, &-inner::after {
    width: 40px;
    height: 3px;
    background-color: map_get($colors,'black');
    @include border_radius(3px);
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &--elastic {
    .hamburger-inner {
      top: 2px;
      transition-duration: 0.275s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      &::before {
        top: 10px;
        transition: opacity 0.125s 0.275s ease;
      }
      &::after {
        top: 20px;
        transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }
    }
    &.is-active .hamburger-inner {
      transform: translate3d(0, 10px, 0) rotate(135deg);
      transition-delay: 0.075s;
      &::before {
        transition-delay: 0s;
        opacity: 0;
      }
      &::after {
        transform: translate3d(0, -20px, 0) rotate(-270deg);
        transition-delay: 0.075s;
      }
    }
  }
}

aside {
  display: none;
  background-color: map_get($colors,'black');
  padding: 30px 0px;
  height: 100%;
  left: -260px;
  top: 0;
  position: fixed;
  transition: all .3s ease .3s;
  width: 260px;
  z-index: 100;
  color: #fff;
  text-align: center;
  overflow-y: auto;

  &.active {
    left: 0;
    transition: all .3s ease .3s;
  }
  nav {
    margin-bottom: 30px;
    ul.mobile {
      padding: 0;
      list-style-type: none;
      & > li > a {
        font-weight: bold;
      }
      li {

        &:before {
          display: none;
        }
        a {
          color: map_get($colors, 'white');
          display: block;
          border-bottom: 1px solid map_get($colors, 'greytext');
          padding: 8px 15px;

        }
        &:hover > a, &.active > a, &.open > a {
          background-color: map_get($colors, 'greytext');
          color: map_get($colors, 'white');
        }
        ul {
          display: none;
          li {
            padding: 0;
            &:before {
              display: none;
            }
            a {
              background-color: #e85356;
              color: map_get($colors, 'white');
            }
            &:hover {
              a {
                background: #DC4A4D;
                color: map_get($colors, 'white');
              }
            }
          }
        }
        &.open, &.active {
          ul {
            display: block;
          }
          .dropdown-backdrop {
            display: none;
          }
        }
      }
    }
  }
  .aside-elems {
    margin: 0 auto;
    width: 148px;
    .aside-elem {
      margin: 0 0 20px 20px;
      display: inline-block;
      float: left;
      &:first-child {
        margin-left: 0;
      }
      span {
        font-size: 35px;
        line-height: 35px;
        &.icon-pinterest {
          .path1 {
            &:before {
              color: rgb(203, 32, 39);
            }
          }
        }
        &.icon-facebook {
          .path1 {
            &:before {
              color: rgb(59, 89, 152);
            }
          }
        }
      }
      a {
        &:hover, &:focus {
          text-decoration: none;
        }
      }
      .change-language {
        a {
          background: map-get($colors, 'white');
          color: map-get($colors, 'black');
          @include border_radius(50%);
          text-align: center;
          display: block;
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: 12px;
          font-family: 'Raleway', sans-serif;
          &:hover {
            background: map_get($colors, 'black');
            border: 1px solid map_get($colors, 'white');
            color: map_get($colors, 'white');
          }
        }
      }
    }
  }
}
